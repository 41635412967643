<template>
  <!--用户中心：用户资料-->
  <div class="section">
    <!--个人信息-->
    <div>
      <div class="section-title">个人信息</div>
      <el-form label-position="left" label-width="80px" class="form1">
        <el-form-item label-width="0">
          <div class="headImg">
            <div class="avatar-text">头像</div>
            <el-avatar :src="data.url" :size="100" />
            <!-- <el-upload class="upload-demo" action>
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload> -->
            <el-upload
              class="upload-demo"
              ref="upload"
              :http-request="uploadFile"
              :action="Url"
              :limit="1"
              :on-change="handleChange"
              :file-list="fileList"
              accept=".jpg,.jpeg"
            >
              <el-button size="small" :loading="loading" type="primary">点击上传</el-button>
            </el-upload>
          </div>
        </el-form-item>

        <el-form-item label="账号昵称">
          <el-input v-model.trim="data.userName" :max="10" size="small" :disabled="true" />
          <el-button type="text" @click="OPenDialogName">编辑</el-button>
        </el-form-item>

        <el-form-item label="联系电话">
          <el-input v-model="phone" size="small" :disabled="true" @input="changeValue" />
          <el-button type="text" @click="OpenDialogphone">编辑</el-button>
        </el-form-item>

        <el-form-item label="联系邮箱">
          <el-input v-model.trim="data.email" size="small" :disabled="true" />
          <el-button type="text" @click="OpenDialogEmail">编辑</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!--企业信息-->
    <div>
      <div class="section-title">企业信息</div>

      <template v-if="data.teamName">
        <el-form label-position="left" label-width="80px">
          <el-form-item label="企业名称">
            {{ data.teamName }}
          </el-form-item>
        </el-form>
      </template>

      <template v-else>无</template>
    </div>

    <!--弹出框：昵称-->
    <el-dialog
      title="编辑昵称"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :show-close="false"
      :visible.sync="dialogName"
      width="500px"
    >
      <div class="form-box">
        <el-form label-position="left" label-width="80px">
          <el-form-item label="账号昵称:">
            <el-input v-model.trim="change.name" maxlength="10" show-word-limit clearable />
          </el-form-item>
        </el-form>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="changeName">确 定</el-button>
        <el-button @click="dialogName = false">取 消</el-button>
      </div>
    </el-dialog>

    <!--弹出框：手机-->
    <el-dialog
      title="编辑手机号"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :show-close="false"
      :visible.sync="dialogphone"
      width="500px"
    >
      <div class="form-box">
        <el-form label-position="left" label-width="80px">
          <el-form-item label="原手机号:">
            <el-input v-model="phone" :disabled="true" @input="changeValue" />
          </el-form-item>

          <el-form-item label="验 证 码:">
            <el-input v-model.trim="oldCode.code" maxlength="6" />
            <div class="captcha-btn">
              <el-button v-show="AuthCode" type="text" @click="changePhone">获取验证码</el-button>
              <el-button v-show="!AuthCode" type="text">{{ auth_time }}秒后重新发送</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="nextCheack">下一步</el-button>
        <el-button @click="dialogphone = false">取 消</el-button>
      </div>
    </el-dialog>

    <!-- 弹出框：新手机号 -->
    <el-dialog
      title="编辑手机号"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :show-close="false"
      :visible.sync="dialogNewPhone"
      width="500px"
    >
      <div class="form-box">
        <el-form label-position="left" label-width="80px">
          <el-form-item label="新手机号:">
            <el-input v-model.trim="NewParam.phone" />
          </el-form-item>

          <el-form-item label="验 证 码:">
            <el-input v-model.trim="NewParam.code" maxlength="6" />
            <div class="captcha-btn">
              <el-button v-show="AuthCode2" type="text" @click="getNewCode">获取验证码</el-button>
              <el-button v-show="!AuthCode2" type="text">{{ auth_time2 }}秒后重新发送</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="finishChange">确定</el-button>
        <el-button @click="dialogNewPhone = false">取 消</el-button>
      </div>
    </el-dialog>

    <!--弹出框：邮箱-->
    <el-dialog
      title="编辑邮箱"
      :visible.sync="dialogEmail"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="500px"
    >
      <div class="form-box">
        <el-form label-position="left" label-width="80px">
          <el-form-item label="邮箱:">
            <el-input v-model.trim="change.email" maxlength="30" show-word-limit clearable />
          </el-form-item>
        </el-form>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="changeEmail">确 定</el-button>
        <el-button @click="dialogEmail = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from '@/api';
// import cookies from '@/utils/cookies'
export default {
  name: 'PersonalDatum',
  data() {
    return {
      change: {
        name: '',
        email: '',
      },
      fileList: [], // 上传的文件列表
      listdata: new FormData(),
      loading: false, // 加载
      phone: '',
      dialogName: false,
      dialogphone: false,
      dialogEmail: false,
      dialogNewPhone: false,
      data: '', // 个人资料
      oldCode: {
        code: '', // 原手机号验证码
      },
      NewParam: {
        phone: '',
        code: '', // 新手机号验证码
      },
      AuthCode: true, // 控制倒计时显示
      auth_time: 60, // 验证码倒计时
      AuthCode2: true, // 新手机号控制显示
      auth_time2: 60, // 新手机号倒计时
    };
  },
  computed: {
    // 上传的地址
    Url() {
      return `${process.env.VUE_APP_API}/psl/user/url/modify`;
    },
  },
  created() {
    this.getInformation();
  },
  methods: {
    changeValue(value) {
      this.phone = value.replace(/[^\d]/g, '');
    },
    // 打开编辑邮箱
    OpenDialogEmail() {
      this.change.email = this.data.email;
      this.dialogEmail = true;
    },
    // 打开编辑联系电话
    OpenDialogphone() {
      this.oldCode.code = '';
      this.dialogphone = true;
    },
    // 打开编辑昵称
    OPenDialogName() {
      this.change.name = this.data.userName;
      this.dialogName = true;
    },
    // 上传图片前
    uploadFile(file) {
      this.listdata.append('file', file.file);
    },
    // 上传头像
    handleChange(file) {
      const isType = file.name.split('.');
      const pdf = isType[isType.length - 1] === 'jpg';
      const jpeg = isType[isType.length - 1] === 'jpeg';
      const isLt1G = file.size / 1024 / 1024 < 50;
      if (!pdf && !jpeg) {
        this.$message.error('上传失败！上传图片只能是JPG和JPEG格式！');
        this.fileList = [];
        return;
      }
      if (!isLt1G) {
        this.$message.error('上传失败！上传文件大小超过50MB！');
        return;
      }
      this.loading = true;
      this.listdata = new FormData();
      API.loadHeadImg(this.listdata)
        .then((res) => {
          if (res.message.success) {
            this.$message({
              title: '成功',
              message: '上传成功',
              type: 'success',
              duration: 2000,
            });
            this.fileList = [];
            this.loading = false;
            this.getInformation();
          }
        })
        .catch((mgs) => {
          console.log(mgs);
        });
    },
    // 获取个人资料
    getInformation() {
      // if (cookies.get('token')) {
      API.information()
        .then((res) => {
          this.data = res.message.data;
          // this.change.name = res.message.data.userName
          this.phone = res.message.data.phone;
          // this.change.email = res.message.data.email
          // this.change.gender = res.message.data.gender
        })
        .catch((mgs) => {
          console.log(mgs);
        });
      // } else {
      //   console.log('登录页面')
      // }
    },

    // 修改账号昵称
    changeName() {
      if (this.change.name.length <= 0 && this.change.name.length > 10) {
        this.$message({
          message: '昵称为1-10个字符，请重试',
          type: 'warning',
          duration: 1000,
        });
      } else {
        this.changeInfo2();
      }
    },
    // 邮箱格式
    cheackEmail() {
      const reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      const res = reg.test(this.change.email);
      return res;
    },
    // 修改邮箱
    changeEmail() {
      if (this.change.email.length <= 0) {
        this.$message({
          message: '邮箱不能为空',
          type: 'warning',
          duration: 1000,
        });
      } else if (!this.cheackEmail()) {
        this.$message({
          message: '邮箱格式不正确',
          type: 'warning',
          duration: 1000,
        });
      } else {
        this.changeInfo();
      }
    },
    // 修改昵称和邮箱
    changeInfo2() {
      API.name({ name: this.change.name })
        .then((res) => {
          if (res.message.success === true) {
            this.$message({
              message: res.message.message,
              type: 'success',
              duration: 1000,
            });
            this.getInformation();
            this.dialogEmail = false;
            this.dialogName = false;
          } else {
            this.$message({
              message: res.message.message,
              type: 'warning',
              duration: 1000,
            });
          }
        })
        .catch((mgs) => {
          console.log(mgs);
        });
    },
    // 修改昵称和邮箱
    changeInfo() {
      API.name({ email: this.change.email })
        .then((res) => {
          if (res.message.success === true) {
            this.$message({
              message: res.message.message,
              type: 'success',
              duration: 1000,
            });
            this.getInformation();
            this.dialogEmail = false;
            this.dialogName = false;
          } else {
            this.$message({
              message: res.message.message,
              type: 'warning',
              duration: 1000,
            });
          }
        })
        .catch((mgs) => {
          console.log(mgs);
        });
    },
    // 发送原手机号的验证码
    changePhone() {
      // if (cookies.get('token')) {
      API.phone()
        .then((res) => {
          if (res.message.success === true) {
            this.$message({
              message: res.message.message,
              type: 'success',
              duration: 1000,
            });
            this.AuthCode = false;
            this.auth_time = 60;
            const authTimeTimer = setInterval(() => {
              this.auth_time -= 1;
              if (this.auth_time <= 0) {
                this.AuthCode = true;
                clearInterval(authTimeTimer);
              }
            }, 1000);
          } else {
            this.$message({
              message: res.message.message,
              type: 'warning',
              duration: 1000,
            });
          }
        })
        .catch((mgs) => {
          console.log(mgs);
        });
      // } else {
      //   console.log('登入页面')
      // }
    },
    // 判断验证码格式正则
    cheackCode() {
      const reg = /^\d{6}$/;
      const res = reg.test(this.oldCode.code);
      return res;
    },
    // 判断验证码格式及下一步
    nextCheack() {
      if (this.oldCode.code.length <= 0) {
        this.$message({
          message: '验证码不能为空',
          type: 'warning',
          duration: 1000,
        });
      } else if (!this.cheackCode()) {
        this.$message({
          message: '验证码格式错误',
          type: 'warning',
          duration: 1000,
        });
      } else {
        this.nextPhone();
      }
    },
    // 验证旧手机验证码
    nextPhone() {
      API.cheackPhone(this.oldCode)
        .then((res) => {
          if (res.message.success === true) {
            this.$message({
              message: res.message.message,
              type: 'success',
              duration: 1000,
            });
            this.NewParam.phone = '';
            this.NewParam.code = '';
            this.dialogphone = false;
            this.dialogNewPhone = true;
          } else {
            this.$message({
              message: res.message.message,
              type: 'warning',
              duration: 1000,
            });
          }
        })
        .catch((mgs) => {
          console.log(mgs);
        });
    },
    // 新手机号格式
    checkNewPhone() {
      const reg = /^1[3|4|5|7|8][0-9]{9}$/;
      const res = reg.test(this.NewParam.phone);
      return res;
    },
    // 判断新验证码格式
    cheackNewCode() {
      const reg = /^\d{6}$/;
      const res = reg.test(this.NewParam.code);
      return res;
    },
    // 获取新手机号验证码
    getNewCode() {
      if (this.NewParam.phone.length <= 0) {
        this.$message({
          message: '手机号不能为空',
          type: 'warning',
          duration: 1000,
        });
      } else if (!this.checkNewPhone()) {
        this.$message({
          message: '请输入正确的手机号',
          type: 'warning',
          duration: 1000,
        });
      } else {
        API.getNewPhone({ phone: this.NewParam.phone })
          .then((res) => {
            if (res.message.success === true) {
              this.$message({
                message: res.message.message,
                type: 'success',
                duration: 1000,
              });
              this.AuthCode2 = false;
              this.auth_time2 = 60;
              const authTimeTimer2 = setInterval(() => {
                this.auth_time2 -= 1;
                if (this.auth_time2 <= 0) {
                  this.AuthCode2 = true;
                  clearInterval(authTimeTimer2);
                }
              }, 1000);
            } else {
              this.$message({
                message: res.message.message,
                type: 'warning',
                duration: 1000,
              });
            }
          })
          .catch((mgs) => {
            console.log(mgs);
          });
      }
    },
    // 新手机号确定
    finishChange() {
      if (this.NewParam.phone.length <= 0 && this.NewParam.code.length <= 0) {
        this.$message({
          message: '手机或验证码不能为空',
          type: 'warning',
          duration: 1000,
        });
      } else if (!this.checkNewPhone()) {
        this.$message({
          message: '手机号格式错误',
          type: 'warning',
          duration: 1000,
        });
      } else if (!this.cheackNewCode()) {
        this.$message({
          message: '验证码格式错误',
          type: 'warning',
          duration: 1000,
        });
      } else {
        API.cheackNewPhone3(this.NewParam)
          .then((res) => {
            if (res.message.success === true) {
              this.$message({
                message: res.message.message,
                type: 'success',
                duration: 1000,
              });
              this.getInformation();
              this.dialogNewPhone = false;
            } else {
              this.$message({
                message: res.message.message,
                type: 'warning',
                duration: 1000,
              });
            }
          })
          .catch((mgs) => {
            console.log(mgs);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// 表单
.form1 {
  .el-input {
    width: 200px;
    margin-right: 10px;
  }
}

// 获取验证码按钮
.captcha-btn {
  position: absolute;
  top: 0;
  right: 10px;
}

// 上传头像
.headImg {
  display: flex;
  align-items: center;
  // 文本
  .avatar-text {
    width: 80px;
  }
  // 上传按钮
  .upload-demo {
    margin-left: 10px;
  }
}
</style>
